<template>
  <div>
    <div class="top_titel">
      <p>授信商户管理（已启用{{ enableCount }}家）</p>
      <el-button
        size="small"
        type="primary"
        icon="el-icon-plus"
        @click="setBindingcode"
        >添加授信商户
      </el-button>
    </div>
    <GlobalTable
      ref="GlobalTable"
      v-loading="loading"
      :columns="tableColumns"
      :data="seachDataList"
      :currentPage="page.pageNum"
      :total="page.total"
      @handleCurrentChange="handleCurrentChange"
    >
      <el-table-column label="添加人" slot="addplople" align="center">
        <template slot-scope="{ row }">
          <span>{{ row.createAdminName }}-{{ row.createAdminAcc }}</span>
        </template>
      </el-table-column>
      <el-table-column label="当前合同状态" slot="copyType" align="center">
        <template slot-scope="{ row }">
          <span>{{
            row.contractState == "00"
              ? "未生效"
              : row.contractState == "01"
              ? "已生效"
              : row.contractState == "10"
              ? "已过期"
              : "已作废"
          }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="当前授信状态"
        slot="creditExtensionStatus"
        align="center"
      >
        <template slot-scope="{ row }">
          <span>{{
            row.effectiveStatus == "00"
              ? "未生效"
              : row.effectiveStatus == "01"
              ? "生效中"
              : row.effectiveStatus == "02"
              ? "已到期"
              : row.effectiveStatus == "03"
              ? "已禁用"
              : "已禁用（已过期）"
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="授信有效期" slot="Validityperiod" align="center">
        <template slot-scope="{ row }">
          <span>{{ row.effectiveTimeRegion || "未设置" }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="授信商家"
        slot="Accreditedmerchant"
        align="center"
      >
        <template slot-scope="{ row }">
          <p
            @click="merchatManagen(row)"
            style="color: #0981ff; cursor: pointer; text-decoration: underline"
          >
            {{ row.fullFinanceCompanyNum || 0 }}家满足授信
          </p>
          <span style="color: #80c269"
            >{{ row.addFinanceCompanyNum || "0" }}家启用中</span
          >
        </template>
      </el-table-column>
      <el-table-column label="当前授信比例" slot="Currentratio" align="center">
        <template slot-scope="{ row }">
          <span>{{ row.financeRatio || "--" }}%</span>
        </template>
      </el-table-column>
      <el-table-column label="启用" slot="lastPutTime" align="center">
        <template slot-scope="{ row }">
          <div v-if="row.financeRatio">
            <el-switch v-model="row.isEnable" @change="changeEnter(row)">
            </el-switch>
            <p @click="addOneMenu(row)" class="blue_underline">查看记录</p>
          </div>
          <span v-else style="color: red">请先完成授信配置</span>
        </template>
      </el-table-column>
      <el-table-column
        width="240"
        fixed="right"
        label="操作"
        slot="operation"
        align="center"
      >
        <template slot-scope="{ row }">
          <el-button size="mini" type="primary" @click="Creditseting(row)"
            >授信配置</el-button
          >
          <el-button size="mini" type="success" @click="handleBtn(row)"
            >授信配置记录</el-button
          >
        </template>
      </el-table-column>
    </GlobalTable>
    <!-- 启用禁用查看记录 -->
    <el-dialog
      title="查看记录"
      :visible.sync="csetionShow"
      :close-on-click-modal="false"
      width="800px"
    >
      <GlobalTable
        ref="GlobalTable"
        v-loading="loading"
        :columns="recordTableColumns"
        :data="recorderList"
        :currentPage="recordpage.pageNum"
        :total="recordpage.total"
        @handleCurrentChange="getrecorderList"
      >
        <el-table-column label="操作类型" slot="copyType" align="center">
          <template slot-scope="{ row }">
            <span>{{ row.isEnable ? "启用" : "禁用" }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作后生效期限" slot="copyTime" align="center">
          <template slot-scope="{ row }">
            <span>{{ row.effectiveTimeRegion || "--" }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作人" slot="copyPlople" align="center">
          <template slot-scope="{ row }">
            <span v-if="row.createType === '01'">授信到期系统自动禁用</span>
            <span v-else
              >{{ row.createAdminName }}-{{ row.createAdminAcc }}</span
            >
          </template>
        </el-table-column>
      </GlobalTable>
    </el-dialog>
    <!-- 授信配置记录 -->
    <el-dialog
      title="授信配置记录"
      :visible.sync="CreditShow"
      :close-on-click-modal="false"
      width="1000px"
    >
      <GlobalTable
        ref="GlobalTable"
        v-loading="loading"
        :columns="creditTableColumns"
        :data="creditAllocationList"
        :currentPage="creditpage.pageNum"
        :total="creditpage.total"
        @handleCurrentChange="getcreditList"
      >
        <el-table-column label="修改人" slot="copyPlople" align="center">
          <template slot-scope="{ row }">
            <span>{{ row.createAdminName }}-{{ row.createAdminAcc }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="修改详情"
          width="600px"
          slot="copyType"
          align="center"
        >
          <template slot-scope="{ row }">
            <span
              >可授信门槛：{{ row.minUsedQuoteOld || 0 }}→{{
                row.minUsedQuoteNew
              }}；单笔授信比例：{{ row.financeRatioOld || 0 }}%→{{
                row.financeRatioNew
              }}%；授信服务费：</span
            >
            <span @click="updateAction('bf', row)"
              >修改前<span class="blue_underline">查看</span>，</span
            >
            <span @click="updateAction('at', row)"
              >修改后<span class="blue_underline">查看</span></span
            >
          </template>
        </el-table-column>
      </GlobalTable>
    </el-dialog>
    <!-- 查看商家授信配置记录修改前后数据 -->
    <el-dialog
      :title="severTitle"
      :visible.sync="severShow"
      :close-on-click-modal="false"
      width="600px"
    >
      <el-table :data="rulerData" border style="width: 100%">
        <el-table-column type="index" label="序号" align="center" width="50">
        </el-table-column>
        <el-table-column prop="date" align="center" label="单笔借款区间">
          <template slot-scope="{ row }">
            <span>{{ row.lower }}≤借款＜{{ row.upper }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="name" align="center" label="计息方式">
          <template slot-scope="{ row }">
            <span>{{
              row.interestType == "01" ? "按比例" : "按固定金额"
            }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="address" align="center" label="服务费">
          <template slot-scope="{ row }">
            <span v-if="row.interestType == '02'">￥{{ row.interest }}</span>
            <span v-else>{{ row.interest }}（万分比）</span>
          </template>
        </el-table-column>
        <el-table-column prop="address" align="center" label="服务费上限">
          <template slot-scope="{ row }">
            <span>￥{{ row.interestLimit }}</span>
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="severShow = false">关闭</el-button>
      </span>
    </el-dialog>
    <!-- 禁用弹框 -->
    <el-dialog
      class="acc_dialog"
      :title="dialogTitle"
      :visible.sync="actiondialogShow"
      :close-on-click-modal="false"
      width="650px"
      @closed="handleDialogClosed"
    >
      <div
        style="font-size: 14px; color: rgb(255, 104, 123); margin: 0 0 10px 0"
      >
        {{
          dialogTitle == "启用提示"
            ? "说明：启用后该回收商合作商户成交订单平台将按授信比例进行预先垫资"
            : "说明：禁用后该商户成交的订单，仍由商户支付全款，平台不再付款。待付款订单仍需定时还款。"
        }}
      </div>
      <div
        style="
          font-size: 18px;
          margin-bottom: 20px;
          font-weight: bold;
          text-align: center;
        "
      >
        是否{{ dialogTitle == "启用提示" ? "启用" : "禁用" }}该商户的授信业务？
      </div>
      <div
        class="command"
        style="margin-bottom: 20px"
        v-if="dialogTitle == '启用提示'"
      >
        <div style="width: 100px">设置授信期限：</div>
        <el-col :span="16">
          <el-date-picker
            :picker-options="pickerOptions"
            value-format="timestamp"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            v-model="lifespanTime"
            style="width: 100%"
            :default-time="['00:00:00', '23:59:59']"
            @change="shouChange"
          ></el-date-picker>
        </el-col>
        <div class="raido_inner" @click="addFenTimeChange">
          <div :class="isImmediate ? 'active_select' : ''"></div>
          <p>立即生效</p>
        </div>
      </div>
      <div class="command">
        <div style="width: 70px">动态口令：</div>
        <el-col :span="15">
          <el-input
            v-model="formOpenPccommand"
            placeholder="请输入谷歌动态口令"
            oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/ig,'')"
          ></el-input>
        </el-col>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="handleDialogClosed">取消</el-button>
        <el-button type="primary" @click.native="submitPopupData()"
          >确定</el-button
        >
      </span>
    </el-dialog>
    <!-- 添加授信商户 -->
    <el-dialog
      title="添加授信商户"
      :visible.sync="bindDloding"
      :close-on-click-modal="false"
      width="1000px"
      @closed="
        bindDloding = false;
        formInline.companyName = '';
        companyIdsList = [];
      "
    >
      <div>
        <el-form :inline="true" :model="formInline" class="demo-form-inline">
          <el-form-item label="回收商家">
            <el-input
              v-model="formInline.companyName"
              placeholder="请输入商家名称查询"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="serchBindSubmit">查询</el-button>
          </el-form-item>
        </el-form>
      </div>
      <GlobalTable
        ref="GlobalTable"
        v-loading="loading"
        :columns="operatorTableColumns"
        :data="opymodelList"
        :currentPage="operatorpage.pageNum"
        layout="sizes,total,prev, pager, next, jumper"
        :maxHeight="600"
        :isSelection="true"
        :isCheckbox="flagSection"
        @handleSelectionChange="operSelectionChange"
        @handleSizeChange="handleSizeChange"
        :total="operatorpage.total"
        @handleCurrentChange="getoperatorList"
      >
        <el-table-column
          label="当前合同生效状态"
          slot="copyType"
          align="center"
        >
          <template slot-scope="{ row }">
            <span>{{
              row.contractState == "00"
                ? "未生效"
                : row.contractState == "01"
                ? "已生效"
                : row.contractState == "10"
                ? "已过期"
                : "已作废"
            }}</span>
          </template>
        </el-table-column>
      </GlobalTable>
      <span slot="footer" class="dialog-footer">
        <el-button
          @click.native="
            bindDloding = false;
            formInline.companyName = '';
            companyIdsList = [];
          "
          >取消</el-button
        >
        <el-button
          type="primary"
          :loading="receiptTypeBtnLoading"
          @click.native="codestoreSubmit"
          >确定</el-button
        >
      </span>
    </el-dialog>
    <!-- 商家授信配置 -->
    <el-dialog
      title="商家授信配置"
      :visible.sync="allocationShow"
      :close-on-click-modal="false"
      width="1000px"
    >
      <p class="b_text">授信比例配置</p>
      <div>
        <div style="display: flex; align-items: center; margin: 15px 0">
          <span>商家实报价≥</span>
          <el-col :span="3">
            <el-input
              maxlength="7"
              type="number"
              v-model="minUsedQuote"
              placeholder="请输入"
            ></el-input>
          </el-col>
          <span>元时，可使用平台授信额度</span>
        </div>
        <div style="display: flex; align-items: center; margin: 15px 0">
          <span>每笔订单平台授信比例</span>
          <el-col :span="3">
            <el-select
              v-model="financeRatio"
              placeholder="请选择"
              clearable
              filterable
            >
              <el-option
                v-for="item in productList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-col>
          <span>%</span>
        </div>
      </div>
      <p class="b_text">授信服务费设置</p>
      <div class="ruluer_style">
        <span>计息规则</span>
        <el-button
          size="mini"
          type="primary"
          icon="el-icon-plus"
          @click="addRoyaltyRule('add')"
          >添加规则</el-button
        >
      </div>
      <el-table border :data="RoyaltyRegionList" class="mb-20 mt-20">
        <el-table-column label="序号" type="index" width="50" align="center">
        </el-table-column>
        <el-table-column prop="rank" label="单笔借款区间" align="center">
          <template slot-scope="{ row }">
            <span>{{ row.lower }}≤借款＜{{ row.upper }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="rank" label="计息方式" align="center">
          <template slot-scope="{ row }">
            <span>{{
              row.interestType == "01" ? "按比例" : "按固定金额"
            }}</span>
          </template>
        </el-table-column>
        <el-table-column label="服务费" prop="interest" align="center">
          <template slot-scope="{ row }">
            <span v-if="row.interestType == '02'">￥{{ row.interest }}</span>
            <span v-else>{{ row.interest }}（万分比）</span>
          </template>
        </el-table-column>
        <el-table-column label="服务费上限" prop="interestLimit" align="center">
          <template slot-scope="{ row }">
            <span>￥{{ row.interestLimit }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          :label-width="'200px'"
          prop="operation"
          align="center"
        >
          <template slot-scope="{ row, $index }">
            <el-button
              size="mini"
              type="primary"
              round
              @click="RoyaltyHandleBtn(row, 'edit', $index)"
              >编辑</el-button
            >
            <el-button
              size="mini"
              type="danger"
              round
              @click="RoyaltyHandleBtn(row, 'del', $index)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="allocationShow = false">关闭</el-button>
        <el-button type="primary" @click.native="allocadSuccess"
          >确定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="安全验证"
      :visible.sync="onelineVisible"
      :close-on-click-modal="false"
      width="500px"
      @closed="onelineClosed"
    >
      <div class="command">
        <div style="width: 85px">动态口令：</div>
        <el-input
          v-model="setcommand"
          placeholder="请输入谷歌动态口令"
          oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/ig,'')"
        ></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="onelineVisible = false">取消</el-button>
        <el-button type="primary" @click.native="onelineSubmit">确定</el-button>
      </span>
    </el-dialog>
    <!-- 商家授信配置规则 -->
    <el-dialog
      :title="RoyaltyRuleTitle"
      :visible.sync="RoyaltyVisible"
      :close-on-click-modal="false"
      width="650px"
      @closed="RoyaltyClosed"
    >
      <div class="rules-form">
        <el-form
          :model="RoyaltyRuleForm"
          :rules="RoyaltyRules"
          ref="RoyaltyRuleForm"
          label-width="130px"
          class="demo-ruleForm"
        >
          <el-form-item label="借款区间:" required>
            <el-col :span="11">
              <el-form-item prop="lower">
                <el-input
                  v-model.trim="RoyaltyRuleForm.lower"
                  oninput="this.value=value.replace(/[^0-9]/g,'')"
                  placeholder="输入起始值"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col style="text-align: center" :span="2">~</el-col>
            <el-col :span="11">
              <el-form-item prop="upper">
                <el-input
                  v-model.trim="RoyaltyRuleForm.upper"
                  oninput="this.value=value.replace(/[^0-9]/g,'')"
                  placeholder="输入结束值"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-form-item>
          <el-form-item label="计息方式" prop="interestType">
            <el-radio-group
              :disabled="royaltyrebateType != ''"
              v-model="RoyaltyRuleForm.interestType"
            >
              <el-radio label="01">按比例</el-radio>
              <el-radio label="02">按固定金额</el-radio>
            </el-radio-group>
          </el-form-item>
          <div
            v-if="
              RoyaltyRuleForm.interestType == '01' ||
              RoyaltyRuleForm.interestType == ''
            "
          >
            <el-form-item label="单笔服务费比例:" prop="interest">
              <el-input
                placeholder="输入单笔服务费比例"
                v-model="RoyaltyRuleForm.interest"
                oninput="this.value=value.replace(/[^0-9]/g,'')"
                @input="
                  () => {
                    if (RoyaltyRuleForm.interest) {
                      RoyaltyRuleForm.interest = Number(
                        RoyaltyRuleForm.interest
                      );
                    }
                  }
                "
              >
                <template slot="append">万分比</template>
              </el-input>
            </el-form-item>
            <el-form-item label="单笔服务费上限:" prop="interestLimit">
              <el-input
                v-model.trim="RoyaltyRuleForm.interestLimit"
                placeholder="输入单笔服务费上限"
                maxlength="5"
                @input="RoyaltyNumberCheck('interestLimit')"
                @blur="RoyaltyNumberFn('interestLimit')"
              ></el-input>
            </el-form-item>
          </div>
          <div v-if="RoyaltyRuleForm.interestType == '02'">
            <el-form-item label="单笔服务费:" prop="interest">
              <el-input
                size="small"
                v-model.trim="RoyaltyRuleForm.interest"
                placeholder="输入单笔服务费"
                @input="RoyaltyNumberCheck('interest')"
                @blur="RoyaltyNumberFn('interest')"
              ></el-input>
            </el-form-item>
            <!-- <el-form-item label="单笔服务费上限:" prop="interestLimit">
              <el-input
                size="small"
                disabled
                v-model.trim="RoyaltyRuleForm.interestLimit"
                placeholder="输入单笔服务费上限"
                @input="RoyaltyNumberCheck('interestLimit')"
                @blur="RoyaltyNumberFn('interestLimit')"
              ></el-input>
            </el-form-item> -->
          </div>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="RoyaltyVisible = false">取消</el-button>
        <el-button type="primary" @click.native="RoyaltyHandleSuccess"
          >确定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="删除确认"
      :visible.sync="informationDelVisible"
      :close-on-click-modal="false"
      width="380px"
    >
      <div class="del-title">
        <div class="sure_tip">确认要删除该数据吗？</div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="informationDelVisible = false"
          >取消</el-button
        >
        <el-button
          type="primary"
          :loading="butLoading"
          @click.native="informationSuccessDel"
          >确定</el-button
        >
      </span>
    </el-dialog>
    <!-- 管理授信商户 -->
    <el-dialog
      title="授信商家管理"
      :visible.sync="managenDlod"
      :close-on-click-modal="false"
      width="1100px"
      @closed="closeManageCredit"
    >
      <div>
        <el-form :inline="true" :model="creditform" class="demo-form-inline">
          <el-form-item label="门店商家">
            <el-input
              size="small"
              v-model="creditform.companyName"
              placeholder="请输入商家名称查询"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="是否启用线上支付">
            <el-select
              size="small"
              v-model="creditform.isEnablePay"
              placeholder="请选择"
              clearable
            >
              <el-option
                v-for="item in onlinePayList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="所属渠道">
            <el-select
              size="small"
              v-model="creditform.matchChannelId"
              placeholder="请选择所属渠道"
              clearable
            >
              <el-option
                v-for="item in channelselectlist"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button size="small" type="primary" @click="serchcreditSubmit"
              >查询</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <div class="dis_center_space_between">
        <div class="faultBtn" style="width: 320px">
          <div
            :class="ManagementTypeTab === '01' ? 'active' : 'none'"
            @click="ManageBtnClick('01')"
          >
            启用中（{{ enableNum }}）
          </div>
          <div
            :class="ManagementTypeTab === '02' ? 'active' : 'none'"
            @click="ManageBtnClick('02')"
          >
            未启用（{{ unEnableNum }}）
          </div>
        </div>
        <div>
          <el-button
            :disabled="managenIdsList.length == 0"
            v-if="ManagementTypeTab === '02'"
            size="small"
            type="success"
            @click="batchAction('start')"
            >批量启用</el-button
          >
          <el-button
            :disabled="managenIdsList.length == 0"
            v-else
            size="small"
            type="danger"
            @click="batchAction('trun')"
            >批量禁用</el-button
          >
        </div>
      </div>
      <GlobalTable
        ref="GlobalTable"
        v-loading="loading"
        :columns="managementTableColumns"
        :data="managementList"
        :currentPage="managementpage.pageNum"
        layout="sizes,total,prev, pager, next, jumper"
        :maxHeight="600"
        :isSelection="true"
        @handleSelectionChange="manigSelectionChange"
        @handleSizeChange="managenSizeChange"
        :total="managementpage.total"
        @handleCurrentChange="getcademanagentList"
      >
        <el-table-column label="联系人" slot="contact" align="center">
          <template slot-scope="{ row }">
            <span>{{ row.companyContact }}-{{ row.companyPhone }}</span>
          </template>
        </el-table-column>
        <el-table-column label="合作状态" slot="copyType" align="center">
          <template slot-scope="{ row }">
            <span>
              {{
                row.applyStatus == "00"
                  ? "邀请待同意"
                  : row.applyStatus == "01"
                  ? "合作中"
                  : row.applyStatus == "02"
                  ? "邀请未通过"
                  : "取消合作"
              }}
              {{
                row.companyIsEnable == "02" &&
                row.applyStatus != "00" &&
                row.applyStatus != "02"
                  ? "(关闭报价)"
                  : ""
              }}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="所属渠道" slot="cancol" align="center">
          <template slot-scope="{ row }">
            <span>{{ row.matchChannelName || "--" }}</span>
          </template>
        </el-table-column>
        <el-table-column label="是否启用线上支付" slot="isEny" align="center">
          <template slot-scope="{ row }">
            <span>{{ row.isEnablePay ? "是" : "否" }}</span>
          </template>
        </el-table-column>
        <el-table-column
          v-if="ManagementTypeTab === '01'"
          label="最近启用时间"
          slot="enableFinanceTime"
          align="center"
        >
          <template slot-scope="{ row }">
            <span>{{ row.enableFinanceTime || "--" }}</span>
          </template>
        </el-table-column>
        <el-table-column
          v-else
          label="最近禁用时间"
          slot="enableFinanceTime"
          align="center"
        >
          <template slot-scope="{ row }">
            <span>{{ row.enableFinanceTime || "--" }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" slot="action" align="center">
          <template slot-scope="{ row }">
            <el-switch v-model="row.isEnableFinance" @change="enyChange(row)">
            </el-switch>
          </template>
        </el-table-column>
      </GlobalTable>
      <span slot="footer" class="dialog-footer">
        <!-- <el-button @click.native="closeManageCredit">取消</el-button>
        <el-button
          type="primary"
          :loading="receiptTypeBtnLoading"
          @click.native="codestoreSubmit"
          >确定</el-button
        > -->
      </span>
    </el-dialog>
    <el-dialog
      :title="RenewalallowTitle"
      :visible.sync="RenewalallowShow"
      :close-on-click-modal="false"
      width="500px"
      @closed="RenewalallowClosed"
    >
      <div class="enable-tip">
        <div>
          {{
            isEnable
              ? "说明：启用后该回收商合作商户成交订单平台将按授信比例进行预先垫资"
              : "说明：禁用后该商户成交的订单，仍由商户支付全款，平台不再付款。待付款订单仍需定时还款。"
          }}
        </div>
        <div class="tip-sure" v-if="isEnable">
          是否确认启用{{ MerchantCopyData.merchantName }}合作的{{
            managenIdsList.length
          }}个门店商的授信业务？
        </div>
        <div class="tip-sure" v-else>
          是否确认禁用{{ MerchantCopyData.merchantName }}合作的{{
            managenIdsList.length
          }}个门店商的授信业务？
        </div>
      </div>
      <div class="command">
        <div style="width: 85px">动态口令：</div>
        <el-input
          v-model="command"
          placeholder="请输入谷歌动态口令"
          oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/ig,'')"
        ></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="RenewalallowShow = false">取消</el-button>
        <el-button type="primary" @click.native="RenewalallowSubmit"
          >确定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import _api from "@/utils/request";
import moment from "moment";
import { MessageBox } from "element-ui";
var postionTime = Date.now();
export default {
  components: {},
  data() {
    return {
      setcommand: "",
      onelineVisible: false,
      enableNum: 0,
      unEnableNum: 0,
      isEnable: false,
      creditMerchant: {},
      MerchantCopyData: {},
      command: "",
      RenewalallowShow: false,
      RenewalallowTitle: "禁用提示",
      ManagementTypeTab: "01",
      onlinePayList: [
        {
          label: "是",
          value: true,
        },
        {
          label: "否",
          value: false,
        },
      ],
      channelselectlist: [],
      butLoading: false,
      informationDelVisible: false,
      productList: [
        {
          label: "10",
          value: 10,
        },
        {
          label: "20",
          value: 20,
        },
        {
          label: "30",
          value: 30,
        },
        {
          label: "40",
          value: 40,
        },
        {
          label: "50",
          value: 50,
        },
        {
          label: "60",
          value: 60,
        },
        {
          label: "70",
          value: 70,
        },
      ],
      financeRatio: null,
      minUsedQuote: "",
      RoyaltyVisible: false,
      royaltyOperation: "del",
      rowId: "",
      RoyaltyRuleForm: {
        lower: "",
        upper: "",
        interestType: "",
        interest: "",
        interestLimit: "",
      },
      RoyaltyRules: {
        lower: [{ required: true, message: "请输入起始值", trigger: "blur" }],
        upper: [{ required: true, message: "请输入结束值", trigger: "blur" }],
        interestType: [
          { required: true, message: "请选择计息方式", trigger: "change" },
        ],
        interest: [
          {
            required: true,
            message: "请输入服务费",
            trigger: "blur",
          },
        ],
        interestLimit: [
          {
            required: true,
            message: "请输入服务费上限",
            trigger: "blur",
          },
        ],
      },
      royaltyrebateType: "",
      RoyaltyRuleTitle: "",
      allocationShow: false,
      RoyaltyRegionList: [],
      severTitle: "修改前服务费规则",
      rulerData: [],
      severShow: false,
      receiptTypeBtnLoading: false,
      formInline: {
        companyName: "",
      },
      creditform: {
        companyName: "",
        isEnablePay: "",
        matchChannelId: "",
      },
      companyIdsList: [],
      managenIdsList: [],
      opymodelList: [],
      managementList: [],
      recorderList: [],
      creditAllocationList: [],
      bindDloding: false,
      managenDlod: false,
      formOpenPccommand: "",
      dialogTitle: "启用提示",

      pickerOptions: {
        disabledDate(time) {
          return time.getTime() <= postionTime;
          // return time.getTime() < Date.now();
        },
      },
      lifespanTime: [],
      isImmediate: false,
      paramsExcel: {},
      posterTypeoptions: [
        {
          value: "01",
          label: "换新活动海报",
        },
      ],
      invokeData: {},
      actiondialogShow: false,
      usedDialogVisible: false,
      imgList: [],
      showViewer: false,
      btnLoading: false,
      csetionShow: false,
      CreditShow: false,
      createinitData: {
        posterImg: "",
        posterOriginalFile: "",
        posterType: "01",
      },
      createformRules: {
        posterType: [
          { required: true, message: "请选择海报类型", trigger: "change" },
        ],
        posterOriginalFile: [
          {
            required: true,
            trigger: "blur",
            message: "请上传压缩文件",
            max: 200,
          },
        ],
        posterImg: [
          {
            required: true,
            trigger: "blur",
            message: "请上传海报图片",
          },
        ],
      },
      initData: {
        entryTime: [
          moment()
            .day(moment().day() - 6)
            .format("YYYY-MM-DD 00:00:00"),
          moment().format("YYYY-MM-DD 23:59:59"),
        ],
      },
      pickerMinDate: null,
      pickerMaxDate: null,
      day31: 30 * 24 * 3600 * 1000,
      datalist: [],
      managementTableColumns: [
        { label: "门店商家", prop: "companyName" },
        { slotName: "contact" }, //联系人
        { slotName: "copyType" }, //状态c
        { slotName: "cancol" }, //渠道
        { slotName: "isEny" }, //是否线上
        { slotName: "enableFinanceTime" }, //是否线上
        // { label: "最近启用时间", prop: "enableFinanceTime" },
        { slotName: "action" }, //操作
      ],
      operatorTableColumns: [
        { label: "回收商家", prop: "merchantName" },
        { label: "联系人", prop: "contactName" },
        { label: "联系电话", prop: "contactPhone" },
        { slotName: "copyType" }, //当前合同状态
        { label: "满足授信门店商", prop: "fullFinanceCompanyNum" },
      ],
      recordTableColumns: [
        { slotName: "copyType" }, //操作类型
        { slotName: "copyTime" }, //操作后生效期限
        { slotName: "copyPlople" }, //操作人
        { label: "操作时间", prop: "createTime" },
      ],
      creditTableColumns: [
        { label: "修改时间", prop: "createTime" },
        { slotName: "copyPlople" }, //操作人
        { slotName: "copyType" }, //操作类型
      ],
      tableColumns: [
        { label: "回收商名称", prop: "merchantName" },
        { label: "联系人", prop: "contactName" },
        { label: "联系电话", prop: "contactPhone" },
        { slotName: "addplople" }, //添加人
        { label: "添加时间", prop: "createTime" },
        { slotName: "copyType" }, //当前合同状态
        { slotName: "creditExtensionStatus" }, //当前授信状态
        { slotName: "Validityperiod" }, //授信有效期
        { label: "合作商家", prop: "allMiddleNum" },
        { slotName: "Accreditedmerchant" }, //授信商家
        { label: "当前账户余额", prop: "balance" },
        { label: "当前待还款", prop: "loanBalance" },
        { slotName: "Currentratio" }, //当前授信比例
        { slotName: "lastPutTime" },
        { slotName: "operation" },
      ],
      seachDataList: [],
      enableCount: "",
      page: {
        pageNum: 1,
        pageSize: 10,
      },
      operatorpage: {
        pageNum: 1,
        pageSize: 10,
      },
      managementpage: {
        pageNum: 1,
        pageSize: 10,
      },
      recordpage: {
        pageNum: 1,
        pageSize: 10,
      },
      creditpage: {
        pageNum: 1,
        pageSize: 10,
      },
      loading: false,
      copyNo: "",
      isPut: false,
    };
  },
  created() {
    this.handleCurrentChange();
  },
  mounted() {},
  computed: {
    SeachParams() {
      return this.$store.state.tagsView.SeachParams;
    },
  },
  watch: {
    SeachParams(newVal) {
      console.log(newVal);
      this.handleCurrentChange();
    },
    RoyaltyRegionList(val) {
      if (!val.length) {
        this.royaltyrebateType = "";
      }
    },
    isImmediate(v) {
      if (v) {
        postionTime = Date.now() - 8.64e7;
        console.log(v, this.lifespanTime);
        if (this.lifespanTime == null || this.lifespanTime.length == 0) {
          this.lifespanTime = [
            Number(moment().format("x")),
            Number(moment().format("x")),
          ];
        } else {
          this.lifespanTime = [
            Number(moment().format("x")),
            this.lifespanTime[1],
          ];
        }
      } else {
        postionTime = Date.now();
        if (this.lifespanTime !== null || this.lifespanTime.length !== 0) {
          this.lifespanTime = [
            Number(moment().format("x")) + 24 * 3600 * 1000,
            this.lifespanTime[1],
          ];
        }
      }
    },
  },
  methods: {
    onelineClosed() {
      this.setcommand = "";
    },
    onelineSubmit() {
      let faceConfig = {
        command: this.setcommand,
        financeRatio: this.financeRatio,
        merchantId: this.configurationMerchantid,
        minUsedQuote: this.minUsedQuote,
        regionList: this.RoyaltyRegionList,
      };
      console.log(faceConfig);
      _api.saveFinanceConfig(faceConfig).then((res) => {
        this.$message({
          message: res.msg,
          type: "success",
        });
        this.onelineVisible = false;
        this.allocationShow = false;
        this.handleCurrentChange();
      });
    },
    getMatchChannelSelect() {
      _api.getMatchChannelSelect().then((res) => {
        this.channelselectlist = res.data;
      });
    },
    allocadSuccess() {
      if (!this.minUsedQuote) {
        return this.$message.error("请输入商家实报价触发平台授信额度金额！");
      }
      if (!this.financeRatio) {
        return this.$message.error("请选择平台授信比例！");
      }
      if (this.RoyaltyRegionList.length == 0) {
        return this.$message.error("请添加计息规则！");
      }
      this.onelineVisible = true;
    },
    shouChange(e) {
      console.log(e);
    },
    RenewalallowClosed() {
      this.command = "";
      this.managenIdsList = [];
      this.getcademanagentList();
    },
    //回收商合作门店商户禁用授信
    RenewalallowSubmit() {
      if (!this.command) {
        return this.$message.error("请输入谷歌动态验证码");
      }
      let updwonPrams = {
        command: this.command,
        companyIds: [],
        isEnableFinance: this.isEnable,
        merchantId: this.MerchantCopyData.merchantId,
      };
      updwonPrams.companyIds = this.managenIdsList.map(
        (item) => item.companyId
      );
      _api
        .cridtenableCompany(updwonPrams)
        .then((res) => {
          if (res.code == 1) {
            this.$message({
              message: "操作成功！",
              type: "success",
            });
            this.command = "";
            this.RenewalallowShow = false;
            this.getcademanagentList();
          } else {
            this.$message.error(res.msg || "系统错误");
          }
        })
        .catch((err) => {});
    },
    batchAction(type) {
      this.RenewalallowTitle = type == "start" ? "启用提示" : "禁用提示";
      this.isEnable = type == "start";
      this.RenewalallowShow = true;
    },
    enyChange(e) {
      this.RenewalallowTitle = e.isEnableFinance ? "启用提示" : "禁用提示";
      this.isEnable = e.isEnableFinance;
      this.creditMerchant = e;
      this.managenIdsList.push(e);
      this.RenewalallowShow = true;
      console.log(e);
    },
    //管理商家列表
    ManageBtnClick(type) {
      this.managenIdsList = [];
      this.ManagementTypeTab = type;
      this.getcademanagentList();
    },
    closeManageCredit() {
      this.creditform = {
        companyName: "",
        isEnablePay: "",
        matchChannelId: "",
      };
      this.managenIdsList = [];
      this.handleCurrentChange();
      this.managenDlod = false;
    },
    merchatManagen(row) {
      this.ManagementTypeTab = "01";
      this.MerchantCopyData = row;
      this.getMatchChannelSelect();
      this.getcademanagentList();
      this.managenDlod = true;
    },
    //授信配置
    Creditseting(data) {
      this.configurationMerchantid = data.merchantId;
      _api.getFinanceConfigInfo({ merchantId: data.merchantId }).then((res) => {
        this.minUsedQuote = res.data.minUsedQuote;
        this.financeRatio = res.data.financeRatio;
        this.RoyaltyRegionList = res.data.regionList;
        this.allocationShow = true;
      });
    },
    informationSuccessDel() {
      this.RoyaltyRegionList = this.RoyaltyRegionList.filter(
        (item) => item.id != this.rowId
      );
      this.informationDelVisible = false;
      this.rowId = "";
    },
    // 商家授信配置设置弹框关闭
    RoyaltyClosed() {
      this.royaltyOperation = "del";
      this.RoyaltyRuleForm = {
        lower: "",
        upper: "",
        interestType: "",
        interest: "",
        interestLimit: "",
        inspectorRewardLimit: "",
      };
      this.rowId = "";
      this.$refs["RoyaltyRuleForm"].resetFields();
    },
    //商家授信配置规则
    RoyaltyHandleSuccess() {
      this.$refs["RoyaltyRuleForm"].validate((valid) => {
        if (valid) {
          let RoyaltyRuleForm = this.RoyaltyRuleForm;
          console.log(RoyaltyRuleForm);
          if (Number(RoyaltyRuleForm.lower) > Number(RoyaltyRuleForm.upper)) {
            this.$message.error("起始值不能大于结束值");
            return;
          }
          if (Number(RoyaltyRuleForm.lower) == Number(RoyaltyRuleForm.upper)) {
            this.$message.error("起始值不能和结束值相等");
            return;
          }
          let params = {
            lower: RoyaltyRuleForm.lower,
            upper: RoyaltyRuleForm.upper,
          };
          if (
            this.RoyaltyRegionList.length &&
            this.royaltyOperation != "edit"
          ) {
            let numArr = [];
            this.RoyaltyRegionList.forEach((item) => {
              numArr.push(item.lower);
              numArr.push(item.upper);
            });
            let iMin = Math.min(...numArr); //最小值
            let iMAX = Math.max(...numArr); //最大值
            if (
              RoyaltyRuleForm.upper != iMin &&
              RoyaltyRuleForm.lower != iMAX
            ) {
              this.$message.error("价位区间不连续");
              return;
            }
            if (
              RoyaltyRuleForm.lower != iMAX &&
              RoyaltyRuleForm.upper != iMin
            ) {
              this.$message.error("价位区间不连续");
              return;
            }
          }
          params.interestType = RoyaltyRuleForm.interestType;
          params.interest = RoyaltyRuleForm.interest;
          if (params.interestType == "02") {
            params.interestLimit = RoyaltyRuleForm.interest;
          } else {
            params.interestLimit = RoyaltyRuleForm.interestLimit;
          }
          if (this.rowId) {
            params.id = this.rowId;
            this.RoyaltyRegionList.splice(
              this.RoyaltyRegionList.findIndex((item) => item.id == this.rowId),
              1,
              params
            );
          } else {
            params.id = Number(params.lower) + 1;
            this.RoyaltyRegionList.push(params);
          }
          this.rowId = "";
          this.RoyaltyVisible = false;
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //转数字
    RoyaltyNumberFn(prop) {
      if (!this.RoyaltyRuleForm[prop]) {
        return;
      }
      var str = this.RoyaltyRuleForm[prop];
      let num = Number(str);
      if (prop == "interestLimit" && num == 0) {
        this.RoyaltyRuleForm["interestLimit"] = 0;
      }
      if (prop == "interest" && num == 0) {
        this.RoyaltyRuleForm["interest"] = 0;
      }
      this.RoyaltyRuleForm[prop] = num;
    },
    //限制一位小数
    RoyaltyNumberCheck(prop) {
      var str = this.RoyaltyRuleForm[prop];
      var len1 = str.substr(0, 1);
      var len2 = str.substr(1, 1);
      //如果第一位是0，第二位不是点，就用数字把点替换掉
      if (str.length > 1 && len1 == 0 && len2 != ".") {
        str = str.substr(1, 1);
      }
      //第一位不能是.
      if (len1 == ".") {
        str = "";
      }
      //限制只能输入一个小数点
      if (str.indexOf(".") != -1) {
        var str_ = str.substr(str.indexOf(".") + 1);
        if (str_.indexOf(".") != -1) {
          str = str.substr(0, str.indexOf(".") + str_.indexOf(".") + 1);
        }
      }
      //正则替换，保留数字和小数点
      str = str.match(/^\d*(\.?\d{0,2})/g)[0] || null;
      this.RoyaltyRuleForm[prop] = str;
    },
    //商家授信配置规则添加
    addRoyaltyRule() {
      if (this.RoyaltyRegionList.length) {
        this.royaltyrebateType = this.RoyaltyRegionList[0].interestType;
        this.RoyaltyRuleForm.interestType =
          this.RoyaltyRegionList[0].interestType;
      }
      this.RoyaltyVisible = true;
      this.RoyaltyRuleTitle = "添加规则";
    },
    //管理人员规则操作
    RoyaltyHandleBtn(row, type, index) {
      console.log(row);
      this.rowId = row.id;
      this.royaltyOperation = type;
      if (type == "del") {
        this.informationDelVisible = true;
      } else {
        this.RoyaltyRuleForm.lower = row.lower;
        this.RoyaltyRuleForm.upper = row.upper;
        this.RoyaltyRuleTitle = "编辑规则";
        this.RoyaltyRuleForm.interestType = row.interestType;
        this.RoyaltyRuleForm.interest = row.interest;
        this.RoyaltyRuleForm.interestLimit = row.interestLimit;
        this.RoyaltyVisible = true;
      }
    },
    updateAction(type, data) {
      this.severTitle = type == "bf" ? "修改前服务费规则" : "修改后服务费规则";
      this.rulerData =
        type == "bf"
          ? data.financeInterestRegionOld
          : data.financeInterestRegionNew;
      this.severShow = true;
    },
    codestoreSubmit() {
      if (this.companyIdsList.length == 0) {
        return this.$message.error("请选择授信商户！");
      }
      let addPrams = {
        merchantIds: [],
      };
      addPrams.merchantIds = this.companyIdsList.map((item) => item.merchantId);
      console.log(this.companyIdsList);
      _api.addToFinanceMerchant(addPrams).then((res) => {
        this.$message({
          message: res.msg,
          type: "success",
        });
        this.bindDloding = false;
        this.handleCurrentChange();
      });
    },
    operSelectionChange(e) {
      this.companyIdsList = e;
    },
    manigSelectionChange(e) {
      this.managenIdsList = e;
    },
    //控制是否能选中
    flagSection(row, index) {
      if (row.contractState == "00" || row.contractState == "01") {
        return true;
      } else {
        return false;
      }
    },
    //搜索商家
    serchBindSubmit() {
      this.getoperatorList();
    },
    //授信商家查询
    serchcreditSubmit() {
      this.getcademanagentList();
    },
    //添加授信商家
    setBindingcode() {
      this.getoperatorList();
    },
    //授信配置记录
    getcreditList(val) {
      console.log(val);
      if (val) {
        this.creditpage.pageNum = val;
      } else {
        this.creditpage.pageNum = 1;
      }
      _api
        .findFinanceConfigLog({
          pageNum: this.creditpage.pageNum,
          pageSize: this.creditpage.pageSize,
          merchantId: this.copyNo,
        })
        .then((res) => {
          if (res.code == 1) {
            this.creditAllocationList = res.data.records;
            this.creditpage.total = res.data.total;
            this.creditpage.pageNum = res.data.current;
          }
        });
    },
    managenSizeChange(e) {
      console.log(e);
      this.managementpage.pageSize = e;
      this.getcademanagentList();
    },
    // 授信商家管理
    getcademanagentList(val) {
      console.log(val);
      if (val) {
        this.managementpage.pageNum = val;
      } else {
        this.managementpage.pageNum = 1;
      }
      this.loading = true;
      _api
        .findFinanceCompanyList({
          pageNum: this.managementpage.pageNum,
          pageSize: this.managementpage.pageSize,
          companyName: this.creditform.companyName,
          isEnableFinance: this.ManagementTypeTab === "01" ? true : false,
          isEnablePay: this.creditform.isEnablePay,
          matchChannelId: this.creditform.matchChannelId,
          merchantId: this.MerchantCopyData.merchantId,
        })
        .then((res) => {
          if (res.code == 1) {
            this.loading = false;
            this.unEnableNum = res.data.unEnableNum;
            this.enableNum = res.data.enableNum;
            this.managementList = res.data.ipage.records;
            this.managementpage.total = res.data.ipage.total;
            this.managementpage.pageNum = res.data.ipage.current;
          }
        });
    },
    getrecorderList(val) {
      console.log(val);
      if (val) {
        this.recordpage.pageNum = val;
      } else {
        this.recordpage.pageNum = 1;
      }
      _api
        .getfindFinanceEnableLog({
          pageNum: this.recordpage.pageNum,
          pageSize: this.recordpage.pageSize,
          merchantId: this.createinitData.merchantId,
        })
        .then((res) => {
          if (res.code == 1) {
            this.recorderList = res.data.records;
            this.recordpage.total = res.data.total;
            this.recordpage.pageNum = res.data.current;
          }
        });
    },
    getoperatorList(val) {
      console.log(val);
      if (val) {
        this.operatorpage.pageNum = val;
      } else {
        this.operatorpage.pageNum = 1;
      }
      _api
        .findCanAddFinanceMerchants({
          pageNum: this.operatorpage.pageNum,
          pageSize: this.operatorpage.pageSize,
          merchantName: this.formInline.companyName,
        })
        .then((res) => {
          if (res.code == 1) {
            this.opymodelList = res.data.records;
            this.operatorpage.total = res.data.total;
            this.operatorpage.pageNum = res.data.current;
            this.bindDloding = true;
          }
        });
    },
    handleDialogClosed() {
      this.isImmediate = false;
      this.lifespanTime = [];
      this.formOpenPccommand = "";
      this.actiondialogShow = false;
      this.handleCurrentChange();
    },
    submitPopupData() {
      if (!this.formOpenPccommand) {
        return this.$message.error("请输入动态口令！");
      }
      if (this.lifespanTime.length == 0 && this.invokeData.isEnable) {
        return this.$message.error("请选择授信期限！");
      }
      const invoParam = {
        command: this.formOpenPccommand,
        effectiveEndTime: this.lifespanTime[1],
        effectiveStartTime: this.lifespanTime[0],
        effectiveTimeRegion: "",
        isEnable: this.invokeData.isEnable,
        isImmediate: this.isImmediate,
        merchantId: this.invokeData.merchantId,
      };
      if (!this.invokeData.isEnable) {
        invoParam.effectiveEndTime = "";
        invoParam.effectiveStartTime = "";
      }
      if (this.isImmediate) {
        invoParam.effectiveStartTime = "";
      }
      _api.updateFinanceEnable(invoParam).then((res) => {
        this.$message({
          message: res.msg,
          type: "success",
        });
        this.actiondialogShow = false;
      });
    },
    changeEnter(e) {
      console.log(e);
      this.invokeData = e;
      this.dialogTitle = e.isEnable ? "启用提示" : "禁用提示";
      this.actiondialogShow = true;
    },
    // 生效时间
    addFenTimeChange() {
      this.isImmediate = !this.isImmediate;
    },
    handleSizeChange(e) {
      console.log(e);
      this.operatorpage.pageSize = e;
      this.getoperatorList();
    },
    //排序
    handleChangeNum(val) {
      const params = {
        id: val.id,
        sort: val.sort,
      };
      _api.updatepoterSort(params).then((res) => {
        if (res.code === 1) {
          this.handleCurrentChange();
          this.$message.success(res.msg);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    //编辑
    addOneMenu(row) {
      console.log(row);
      this.createinitData = row;
      this.getrecorderList();
      this.csetionShow = true;
    },
    // 查看示例图大图
    seeSampleImage(posterImg) {
      this.imgList = [];
      this.showViewer = true;
      this.imgList.push(posterImg);
    },
    // 确认添加海报
    submitAdd(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.btnLoading = true;
          console.log(this.createinitData);
          _api
            .saveposterInfo(this.createinitData)
            .then((res) => {
              if (res.code === 1) {
                this.csetionShow = false;
                this.$message({
                  message: "编辑成功！",
                  type: "success",
                });
                this.handleCurrentChange(1);
              }
              this.btnLoading = false;
            })
            .catch((err) => {
              this.btnLoading = false;
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //删除图片
    handleUploadDelete() {
      this.createinitData.posterImg = "";
    },
    // 上传图片后，将图片信息保存到对应参数中
    handleUploadChange(url) {
      this.createinitData.posterImg = url;
    },
    //删除文件
    handleUploadfileDelete() {
      this.createinitData.posterOriginalFile = "";
    },
    // 上传文件后，将信息保存到对应参数中
    handleUploadFileChange(url) {
      console.log(url);
      this.createinitData.posterOriginalFile = url;
    },
    //添加海报弹窗关闭
    resetForm() {
      this.csetionShow = false;
    },
    // 导出订单方法
    exportExcel(e) {
      let redenvelopeslist = [];
      redenvelopeslist = {
        startTime: this.datalist.startTime || this.entryTime[0],
        endTime: this.datalist.endTime || this.entryTime[1],
        pageNum: this.page3.pageNum,
        pageSize: 10,
        staffName: this.datalist.staffName || "",
        staffMobile: this.datalist.staffMobile || "",
      };

      console.log(redenvelopeslist);
      _api.redpacketdetailexcel(redenvelopeslist).then((res) => {
        console.log(res);
        const content = res;
        const blob = new Blob([content]);

        let reader = new FileReader(); // 创建读取文件对象
        reader.addEventListener("loadend", () => {
          //
          try {
            let data = JSON.parse(reader.result);
            if (data.code === 0) {
              MessageBox.alert(data.msg, "错误提示", {
                type: "error",
              });
            }
          } catch (err) {
            let start = moment(Number(redenvelopeslist.startTime)).format(
              "YYYY.MM.DD"
            );
            let end = moment(Number(redenvelopeslist.endTime)).format(
              "YYYY.MM.DD"
            );
            const fileName = `可提现奖励明细.xlsx`;
            if ("download" in document.createElement("a")) {
              // 非IE下载
              const elink = document.createElement("a");
              elink.download = fileName;
              elink.style.display = "none";
              elink.href = URL.createObjectURL(blob);
              document.body.appendChild(elink);
              elink.click();
              URL.revokeObjectURL(elink.href); // 释放URL 对象
              document.body.removeChild(elink);
            } else {
              // IE10+下载
              navigator.msSaveBlob(blob, fileName);
            }
          }
        });
        reader.readAsText(blob, "utf-8");
      });
    },
    //   切换页
    handleCurrentChange(val) {
      if (val) {
        this.pageNum = val;
        this.page.pageNum = val;
      } else {
        this.page.pageNum = 1;
      }
      const baseRequest = {
        contactName: this.SeachParams.contactName,
        contactPhone: this.SeachParams.contactPhone,
        contractState: "",
        effectiveStatus: this.SeachParams.effectiveStatus,
        financeRatio: this.SeachParams.financeRatio,
        merchantId: this.SeachParams.merchantId,
        endTime: this.SeachParams.endTime,
        startTime: this.SeachParams.startTime,
        pageNum: this.page.pageNum,
        pageSize: this.page.pageSize,
      };
      this.loading = true;
      _api
        .getfindFinanceMerchantList(baseRequest)
        .then((res) => {
          console.log(res);
          if (res.code === 1) {
            this.seachDataList = res.data.ipage.records;
            this.page.total = res.data.ipage.total;
            this.page.pageNum = res.data.ipage.current;
            this.enableCount = res.data.enableCount;
            this.loading = false;
          }
        })
        .finally(() => {
          this.loading = false;
          if (typeof this.SeachParams.disuseLoding == "function") {
            this.SeachParams.disuseLoding();
          }
        });
    },
    // 授信配置记录
    handleBtn(row) {
      this.creditAllocationList = [];
      this.copyNo = row.merchantId;
      this.getcreditList();
      this.CreditShow = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.enable-tip {
  color: #ff8080;
  font-size: 14px;
  margin-bottom: 20px;

  .tip-sure {
    margin-top: 10px;
    color: #333333;
    font-size: 16px;
    text-align: center;
  }
}
.dis_center_space_between {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.faultBtn {
  width: 280px;
  height: 34px;
  background: #f9fbfd;
  border: 1px solid #c1d1ff;
  box-shadow: 0px 4px 8px 0px rgba(196, 197, 198, 0.2);
  border-radius: 21px;
  display: flex;
  cursor: pointer;

  .active {
    text-align: center;
    color: white;
    width: 50%;
    height: 14px;
    font-size: 14px;
    font-family: FZLanTingHei-M-GBK;
    font-weight: 400;
    line-height: 32px;
    height: 32px;
    background: #0981ff;
    border-radius: 20px;
  }

  .none {
    text-align: center;
    color: #333;
    width: 50%;
    height: 14px;
    font-size: 14px;
    font-family: FZLanTingHei-M-GBK;
    font-weight: 400;
    line-height: 32px;
    height: 32px;
    border-radius: 20px;
  }
}

.ruluer_style {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.b_text {
  font-size: 15px;
  color: #0981ff;
}

.staffNamebox {
  padding: 2px 4px;
  border-radius: 4px;
  margin-right: 5px;
  color: #fff;
}

.top_titel {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.content_copy {
  word-break: break-all;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  overflow: hidden;
}

.tip {
  color: #ff687b;
}

.andleShowbox {
  border: 1px solid #0981ff;
  width: 200px;
  display: flex;
  justify-content: space-between;
  border-radius: 4px;
  margin-bottom: 20px;

  .andleShowstyle {
    width: 100px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    color: #0981ff;
    cursor: pointer;
    user-select: none;
  }

  .andleShowcolor {
    background-color: #0981ff;
    color: #fff;
  }
}

.raido_inner {
  margin-left: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;

  > div {
    border: 1px solid #0981ff;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background: #fff;
    position: relative;

    &::after {
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: #fff;
    }
  }

  > p {
    font-size: 14px;
    margin-left: 10px;
  }

  .active_select {
    border-color: #0981ff;
  }

  .active_select::after {
    background: #0981ff;
  }
}

.command {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.blue_underline {
  color: #0981ff;
  cursor: pointer;
  text-decoration: underline;
}

//去除数字框上下箭头
/deep/ input::-webkit-outer-spin-button,
/deep/ input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

/deep/ input[type="number"] {
  -moz-appearance: textfield;
}
</style>
